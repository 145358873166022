<template>
    <div>
        <div class="max-w-lg mx-auto px-4 md:px-6 pt-8">
            <TinyNavigation :prev="{name: 'settings'}" />
            <h1 class="text-white font-semibold text-2xl pt-8">{{ t('edit_password') }}</h1>
            <form @submit.prevent="handleSubmit" class="mt-5">
                <div class="grid grid-cols-1 gap-4">
                    <TextInput v-model:value="form.current_password" v-model:errors="errors.current_password" :alt-styling="true" :label="t('current_password')" password />
                    <TextInput v-model:value="form.password" v-model:errors="errors.password" :alt-styling="true" :label="t('password')" password />
                    <TextInput v-model:value="form.password_confirmation" v-model:errors="errors.password_confirmation" :alt-styling="true" :label="t('password_confirmation')" password />
                </div>
                <div class="mt-8">
                    <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="button w-full">
                            {{ t('save') }}
                        </button>
                    </span>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import { updateProfile } from "@/helpers/api/AccountHelper";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import TinyNavigation from "@/components/TinyNavigation";
import messages from "@/helpers/storage/MessageHelper";
import TextInput from "@/components/forms/TextInput";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ProfileEditPassword",
    components: { 
        TinyNavigation,
        TextInput,
    },
    setup() {
        return useI18n({
            viewPrefix: "ProfileEditPassword"
        });
    },
    methods:{
        handleSubmit() {
            startSpinner();

            updateProfile(this.form)
                .then(() => {
                    messages.success(this.t('messages.password_saved'));
                    this.form.current_password = "";
                    this.form.password = "";
                    this.form.password_confirmation = "";
                })
                .catch(err => this.errors = err.errors)
                .finally(() => stopSpinner())
        }
    },
    data(){
        return {
            form: {
                current_password: "",
                password: "",
                password_confirmation: "",
            },
            errors: {
                current_password: [],
                password: [],
                password_confirmation: [],
            },
        };
    },
}
</script>